#navbar {
    overflow: hidden;
    background-color: #333;
    border-bottom: 1px solid black
  }
  
  /* Navbar links */

.links {
    margin-right: 5%;
}

#navbar a {
    float: right;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 1%;
    margin-left: 2%;
    text-decoration: none;
    font-size: 1.3rem;
    -ms-transition: .2s ease-in-out;
    -webkit-transition: .2s ease-in-out;
}

#navbar a:hover {
    color: lightgreen;
}

@media only screen and (max-width: 600px) {
    #navbar {
      width: 100vw;
    }
  }