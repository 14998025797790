.App {
  font-family: 'Spectral', serif;
  -webkit-animation: name 1s; /* Safari, Chrome and Opera > 13.1 */
  -moz-animation: name 1s; /* Firefox < 16 */
  -ms-animation: name 1s; /* Internet Explorer */
  -o-animation: name 1s; /* Opera < 13.1 */
  animation: name 1s;
  width: 100%
}

.Home {
  background-color: ghostwhite;
}

.Head {
  display: flex;
  margin: auto;
  border-bottom: 1px solid lightgreen;
  width: 90%;
  justify-content: space-between;
}

.A {
  display: flex;
  margin-left:30%;
  width: 20%;
  margin: 0;
}

.A a {
  text-decoration: none;
  color: black;  
  width: auto;
  margin: auto;
  transition: all .4s ease-in-out;
}

.A a:hover {
  transform: scale(1.5);
  color: lightgreen;
}

#name {
  font-size: 4rem;
  margin-top: 4%; 
  width: 50%;
}

#name h2 {
  font-size: 2rem;
  margin-left: 5%;
  margin-top: -2%;
  text-decoration: none;
}

.Head .Email {
  font-size: 2rem;
  margin-top: -1%;

}

.stack {
  margin: 3% 10%;
}

.techs {
  display: flex;
  justify-content: space-evenly;
}

.tech {
  width: 15%;
  text-align: center;
}

.tech h3 {
  font-size: 1.5rem;
}

.tech i {
  -ms-transition: .7s ease-in-out;
  -webkit-transition: .7s ease-in-out;
}

.tech i:hover {
  -ms-transform: rotate(360deg); /* IE 9 */
  -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
  transform: rotate(360deg);
  color: lightgreen;
}

.stack h1 {
  text-align: center;
  font-size: 3rem;
  padding-bottom: 2%;
  border-bottom: 1px solid lightgreen;
}

.bottom {
  margin: 10%;
  margin-bottom: 10%;
  /* margin-top: 15%; */
  padding-bottom: 5%;
  border-top: 1px solid lightgreen;
  border-bottom: 1px solid lightgreen;
}

.bottom h1 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 5%;
  margin-top: 5%;
}

.projects {
  height: 50%;
}

.project {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 8%;
  height: 40%;
}

.project img {
  border-radius: 1%;
  margin-top: 3%;
  height: 300px;
}

.Description a {
  text-decoration: none;
  color: black;
  transition: all .2s ease-in-out;
  margin-right: 2%;
}

.Description .Project-title:hover {
  transform: scale(1.5);
  color: lightgreen;
  text-decoration: underline;
}

.Description a:hover {
  transform: scale(1.5);
  color: lightgreen;
}

.Description {
  margin-top: -2%;
  text-align: center;
}

.Description p {
  margin: -5% 5%;
  font-size: 1.2rem;
}

@keyframes name {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.About {
  background-color: ghostwhite;
  height: 100%
}

.About-Title {
  font-size: 4rem;
  width: 90%;
  margin: auto;
  margin-bottom: -2%;
  margin-top: -2%;
}

#top {
  border-top: 1px solid lightgreen;
  display: flex;
  height: 50%;
  margin: 0% 10%;
  margin-bottom: 10%;

}

.boxes {
  text-align: center;
  justify-content: center;
  width: 40%;
  height: 80%;
  margin: auto;
  margin-top: 2%;
}

.boxes img {
  height: 100%;
  border-radius: 2%;
}

.boxes p {
  font-size: 1.25em;

}

.boxes a {
  text-decoration: none;
  color: rgb(14, 197, 14)
}

.boxes img {
  width: 20rem;
  margin-top: 5%;
}

#footerbar {
  overflow: hidden;
  background-color: #333;
  color: #f2f2f2;
  text-align: center;
  bottom: auto;
} 

@media only screen and (max-width: 600px) {
  body {
    background-color: ghostwhite;
  }
  .Head {
    width: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .A {
    width: 100%;
    margin-bottom: 5%;
  }

  .A a {
    text-decoration: none;
    color: black;  
    width: auto;
    margin: auto;
    transition: all .4s ease-in-out;
  }
  
  .A a:hover {
    transform: scale(1.5);
    color: lightgreen;
  }
  
  #name {
    font-size: 3rem;
    margin-top: 4%; 
    width: 100%;
  }
  
  #name h2 {
    font-size: 2rem;
    margin-left: 5%;
    margin-top: -2%;
    text-decoration: none;
  }
  
  .Head .Email {
    font-size: 1.5rem;
    margin-top: -1%;
  
  }

  .stack h1 {
    font-size: 2rem;
  }

  .tech {
    width: auto;
  }
  
  .tech h3 {
    font-size: 1.2rem;
  }
  
  .tech i {
    display: none;
  }

  .bottom {
    width: 100%;
    text-align: center;
    margin: auto;
  }
  
  .project {
    display: flex;
    flex-direction: column;
    width: 100vw
  }

  .project img {
    display: none;
  }

  .About {
    background-color: ghostwhite;
  }

  .About-Title {
    font-size: 3rem;
  }

  #top {
    display: flex;
    flex-direction: column;
  }

  .boxes {
    width: 100%
  }
  
  .boxes img {
    display: none;
  }
  
}